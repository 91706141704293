.Contact a {
    color: black;
    transition: 0.3s;
}

.Contact a:hover {
    opacity: 0.7 !important;
    transition: 0.3s;
}

.Contact__Backdrop {
    height: 100%;
    width: 100%;
    z-index: 100 !important;
}

.Contact__Loader {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.override {
    color: black !important; 
}

.title {
    padding: 0 5%;
    margin-top: 6%;
    font-size: 40px;
    font-weight: 300;
    letter-spacing: 8px;
    opacity: 0.7;
}

.icon {
    margin: 5px 5px 0 0;
    color: black !important;
}

.address {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 200px;
}

.MuiChip-label {
    color: black !important; 
}

.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 100vh;
    font-size: 13px;
    background: #ECE9E6;
    background: -webkit-linear-gradient(to bottom, #FFFFFF, #ECE9E6);
    background: linear-gradient(to bottom, #FFFFFF, #ECE9E6);
}

.Contact > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.Contact__first-section {
    height: 100%;
    flex-basis: 30%;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
}

.Contact__first-section > div:last-child {
    margin-top: 50px;
}

.Contact__info {
    text-align: left;
}

.Contact__info > * {
    margin-top: 15px;
}

.Contact__info > p {
    font-size: 20px;
    font-weight: 300;
}

.Contact__info > div {
    display: flex;
    align-items: flex-start;
}

.Contact__info > div p {
    /* margin-left: 7px; */
}

.Contact__second-section {
    flex-basis: 70%;
    padding: 0 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

.Contact__second-section > * {
    margin-top: 30px !important;
}

.Contact__second-section > *:first-child {
    margin-top: none !important;
}

@media (max-width: 1000px) {
    .title {
        font-size: 35px;
        letter-spacing: 6px;
    }

    .Contact__first-section {
        flex-basis: 25%;
        padding: 0 7%;
    }

    .Contact__second-section {
        flex-basis: 75%;
        padding: 0 7%;
    }
}

@media (max-width: 770px) {
    .title {
        margin-top: 10%;
        font-size: 40px;
        opacity: 1;
    }

    .Contact {
        height: auto;
    }

    .Contact > div {
        display: flex;
        flex-direction: column-reverse;
        padding: 1% 2%;
    }

    .Contact__first-section {
        width: 100%;
        padding: 5% 10%;
        margin-bottom: 7vh;
    }

    .Contact__second-section {
        width: 100%;
        padding: 2% 10%;
    }
}

@media (max-width: 600px) {
    .snackbar {
        height: auto !important;
    }
}

@media (max-width: 590px) {
    .title {
        margin-top: 15%;
        font-size: 30px;
        padding: 0 7%;
    }

    .Contact__first-section {
        padding: 8% 20%;
    }
}

@media (max-width: 400px) {
    .title {
        margin-top: 20%;
        font-size: 25px;
        letter-spacing: 5px;
    }

    .Contact__info > p {
        font-size: 15px;
    }

    .Contact {
        font-size: 11px;
    }

    .Contact__second-section {
        padding: 6% 10%;
    }
}