/*Animations*/
@keyframes zoom-services {
    0% {
        transform: scale(1.1, 1.1);
    }
    40% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes title-services {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.extraPadding {
    padding-bottom: 10% !important;
}

.none {
    display: block;
}

.custom-line::before {
    background: #459cc2 !important;
}

.vertical-timeline-element-icon {
    background: #2f6a83;
}

.vertical-timeline-element-content {
    background: transparent !important;
}

.services-scroll {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
    z-index: 5;
    color: black;
    transition: 0.2s;
}

.services-scroll:hover {
    transition: 0.2s;
    transform: translateY(5px);
    color: inherit;
    opacity: 0.5;
}

.services-scroll-up {
    position: absolute;
    left: 45%;
    right: 45%;
    margin: 0 auto;
    bottom: 20px;
    z-index: 5;
    color: black;
    transition: 0.2s;
}

.services-scroll-up:hover {
    transition: 0.2s;
    transform: translateY(5px);
    color: inherit;
    opacity: 0.5;
}

figure {
    background: rgba(255, 255, 255) !important;
    width: 100% !important;
    height: 100% !important;
}

figure:hover {
    background: transparent !important;
}

figure > img {
    width: 100% !important;
    height: 100% !important;
}

figcaption {
    padding: 4%;
    background: #0F2027 !important;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027) !important;
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027) !important;
    color: white !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

figcaption p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 18px;
    margin: 5% 0;
}

figcaption > p:first-child {
    font-size: 30px;
    letter-spacing: 10px;
    line-height: 50px;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 1px;
    color: white;
    transition: 0.4s;
}

figcaption > p:first-child > .firstNone {
    display: none;
}

.Services__first__half__figcaption p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 18px;
    margin: 5% 0;
}

figcaption p > span {
    margin-left: 5px;
}

.Services__first__half__figcaption p > span {
    margin-left: 5px;
}

.Services {
    font-size: 13px;
    font-weight: 300;  
}

.Services__description {
    display: flex;
    flex-wrap: wrap;
    color: white;
    height: 100vh;
    position: relative;
    animation: zoom-services 1.2s ease-in-out;
}

.Services__description > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    word-spacing: 9999rem;
    letter-spacing: 10px;
    line-height: 140px;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    z-index: 0;
    animation: title 1.5s;
}

.Services__description div {
    flex-basis: 33%;
    transition: 0.2s;
    background: rgba(0, 0, 0, 0.3);
}

.Services__description div:hover {
    transition: 0.2s;
    background: rgba(0, 0, 0, 0);
}

.Services__first {
    position: relative;
    height: 100vh;
    display: flex;
}

.Services__first__half {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.Services__first__half > p:first-child {
    transition: 0.4s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    letter-spacing: 10px;
    line-height: 50px;
    z-index: 100;
}

.Services__first__half:hover > p:first-child {
    display: none;
}

.Services__first__half__figcaption > p:first-child {
    font-size: 30px;
    letter-spacing: 10px;
    line-height: 50px;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 1px;
    color: white;
    transition: 0.4s;
}

.Services__first__half__figcaption > p:first-child > .firstNone {
    display: none;
}

.Services__first__half__figcaption {
    padding: 4%;
    background: #0F2027 !important;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027) !important;
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027) !important;
    color: white !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
}

.Services__timeline {
    padding: 4%;
    height: auto;
    position: relative;
    color: white;
}

.Services__timeline-element {
    margin: 20% 0% !important;
}

.Services__timeline-element:first-child {
    margin-top: 2% !important;
}

.Services__timeline-element:last-child {
    margin-bottom: 2% !important;
}

.Services__timeline-title {
    font-size: 22px !important;
    text-align: left;
}

.Services__timeline-text {
    font-size: 25px !important;
    font-weight: 300 !important;
    text-align: left !important;
    /* background: red !important; */
}

.Services__description__carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3% 10% 0 10%;
    background: #0F2027; 
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color: white;
}

.Services__description__carousel__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 60px;
    text-align: left;
}

.Services__description__carousel__item > p:first-child {
    font-size: 22px;
    font-weight: 400;
}

.Services__description__carousel__item > p:last-child {
    font-size: 20px;
}

.Services__gallery {   
    background: transparent;
    height: auto;
}
.react-photo-gallery--gallery {
    background: black;
}

.Services__last {
    color: white;
    padding: 8% 10%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: 22px;
    line-height: 60px;
    position: relative;
    background: transparent;
}

@media (max-height: 680px) {
    .Services__description {
        height: 680px;
    }
}

@media (max-height: 750px) {
    .Services__first__half {
        height: 750px;
    }
}

@media (max-width: 1000px) {
    figcaption p {
        font-size: 15px;
        margin: 7% 0;
    }

    .Services__first__half__figcaption p {
        font-size: 15px;
        margin: 7% 0;
    }

    .Services__description > p {
        letter-spacing: 10px;
        font-size: 48px;
    }

    .Services__first__half > p:first-child {
        font-size: 25px;
    }

    .Services__first__half-mobile > p:first-child {
        font-size: 25px;
    }

    .Services__timeline {
        padding: 4% 4% 7% 4%;
    }

    .Services__timeline-element {
        margin: 17% 0% !important;
    }
    
    .Services__timeline-title {
        font-size: 18px !important;
    }
    
    .Services__timeline-text {
        font-size: 20px !important;
    }

    .Services__description__carousel {
        padding-top: 8%;
    }
    
    .Services__description__carousel__item > p:first-child {
        font-size: 20px;
    }
    
    .Services__description__carousel__item > p:last-child {
        font-size: 18px;
    }

    .Services__last {
        font-size: 20px;
    }
    
}

@media (max-width: 770px) {
    .services-scroll-up {
        display: none;
    }

    .services-scroll {
        display: none;
    }


    figcaption p {
        margin: 5% 0;
    }

    .Services__first__half__figcaption p {
        margin: 5% 0;
    }

    .services-scroll {
        bottom: 10px;
    }

    .services-scroll:hover {
        transform: none;
    }

    .services-scroll-up {
        bottom: 0px;
    }

    .services-scroll-up:hover {
        transform: none;
    }

    .Services__first {
        height: 200vh;
        flex-direction: column;
    }

    .Services__first__half {
        flex-basis: 50%;
    }

    /* .Services__description div {
        display: none;
    } */

    .Services__timeline-element {
        margin: 13% 0% !important;
    }
    
    .Services__timeline-title {
        font-size: 16px !important;
    }
    
    .Services__timeline-text {
        font-size: 16px !important;
    }

    .Services__description > p {
        font-weight: 400;
        letter-spacing: 8px;
        font-size: 43px;
    }

    .Services__description__carousel {
        padding-top: 8%;
    }
    
    .Services__description__carousel__item {
        line-height: 50px;
    }

    .Services__description__carousel__item > p:first-child {
        font-size: 18px;
    }
    
    .Services__description__carousel__item > p:last-child {
        font-size: 16px;
    }

    .Services__last {
        font-size: 18px;
        line-height: 50px;
    }
}

@media (max-width: 600px) {
    figcaption p {
        text-align: center;
        margin: 6% 0;
    }

    .Services__first__half__figcaption p {
        text-align: center;
        margin: 6% 0;
    }

    .Services__timeline {
        padding: 7%;
    }

    .Services__timeline-title {
        font-size: 14px !important;
    }
    
    .Services__timeline-text {
        font-size: 14px !important;
    }

    .Services__description > p {
        letter-spacing: 6px;
        font-size: 40px;
        padding: 3%;
    }
    
    .Services__description__carousel__item {
        line-height: 45px;
    }

    .Services__description__carousel__item > p:first-child {
        font-size: 16px;
    }
    
    .Services__description__carousel__item > p:last-child {
        font-size: 13px;
    }

    .Services__last {
        font-size: 16px;
        line-height: 45px;
    }
}

@media (max-width: 450px) {
    .extraPadding {
        padding-bottom: 15% !important;
    }

    .vertical-timeline-element-icon {
        height: 34px !important;
        width: 34px !important;
    }

    figcaption p {
        font-size: 12px;
        margin: 8% 0;
    }

    .Services__first__half__figcaption p {
        font-size: 12px;
        margin: 8% 0;
    }

    .control-dots {
        display: none !important;
    }

    .Services__first__half > p:first-child {
        font-size: 18px;
    }

    .Services__timeline-title {
        font-size: 12px !important;
    }
    
    .Services__timeline-text {
        font-size: 12px !important;
    }

    .Services__description > p {
        font-size: 25px;
        letter-spacing: 5px;
        line-height: 100px;
    }

    .Services__description__carousel {
        height: auto;
        padding-top: 12%;
        padding-left: 12%;
        padding-right: 12%;
    }

    .Services__description__carousel__item {
        line-height: 40px;
    }

    .Services__last {
        font-size: 14px;
        line-height: 40px;
    }
}