.Dashboard {
    padding: 5% 10%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.Dashboard__Intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-weight: 300;    
    width: 100%;
    font-size: 20px;
}

.Dashboard__Intro > * {
    margin: 1% 0;
}

.Dashboard__Intro > p:first-child {
    font-size: 50px;
}

.Dashboard__Intro > p:nth-of-type(2) {
    letter-spacing: 8px;
}

.Dashboard__Cards {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}

.Dashboard__Cards > * {
    margin: 2% 5%;
}

@media (max-height: 700px) {
    .Dashboard {
        height: 700px;
    }
}

@media (max-width: 1200px) {
    .Dashboard {
        padding-top: 8%;
    }
}

@media (max-width: 1000px) {
    .Dashboard {
        padding-top: 10%;
    }

    .Dashboard__Intro > p:first-child {
        font-size: 45px;
    }
}

@media (max-width: 800px) {
    .Dashboard {
        padding-top: 10%;
    }

    .Dashboard__Intro {
        text-align: center;
        align-items: center;
    }

    .Dashboard__Cards > * {
        margin: 5% 0;
    }

    .Dashboard__Cards {
        padding: 1% 3%;
    }
}

@media (max-width: 600px) {
    .Dashboard {
        padding-top: 10%;
    }

    .Dashboard__Intro > p:first-child {
        font-size: 40px;
    }

    .Dashboard__Intro > p:nth-of-type(2) {
        font-size: 16px;
    }

    .Dashboard__Cards > * {
        margin: 9% 0;
    }

    .Dashboard__Cards {
        padding: 1% 6%;
    }
}

@media (max-width: 450px) {
    .Dashboard {
        padding-top: 20%;
    }

    .Dashboard__Intro > p:first-child {
        font-size: 35px;
    }

    .Dashboard__Intro > p:nth-of-type(2) {
        font-size: 14px;
        letter-spacing: 7px;
        margin-top: 4%;
    }

    .Dashboard__Cards > * {
        margin: 20% 0;
    }
}