.contactLink {
    color: black !important;
    font-weight: 400;
    transition: 0.3s;
}

.contactLink:hover {
    opacity: 0.7;
    transition: 0.3s;
}

hr {
    margin: 2% 0;
}

span {
    opacity: 0.7;
}

.header {
    font-size: 20px;
    color: #002ab3;
}

.italic {
    font-style: italic;
}

.italic > p:first-child {
    font-style: normal;
}

.italic > p:last-child {
    font-style: normal;
}

.contact {
    align-self: center;
    font-size: 18px;
}

.Privacy {
    padding: 8% 6%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: 16px;
    font-weight: 300;  
    background: #ECE9E6;
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
}

.Privacy > p:first-child {
    margin-top: 10px;
}

@media (max-width: 1000px) {
    .Privacy {
        padding: 10% 7%;
        font-size: 14px;
    }

    .header {
        font-size: 18px;
    }

    .contact {
        font-size: 16px;
    }
}

@media (max-width: 770px) {
    .Privacy {
        padding: 8% 10%;
    }
}

@media (max-width: 590px) {
    .Privacy {
        padding: 10%;
        font-size: 12px;
    }

    .header {
        font-size: 16px;
    }

    .contact {
        font-size: 14px;
    }
}

@media (max-width: 420px) {
    .Privacy {
        padding: 13%;
    }
}