/*General*/
.bold {
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 1px;
}

.bold_2 {
    font-size: 20px;
}

.chip-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10%;
}

.chip-container > * {
    margin-right: 10%;
}

.italic {
    font-style: italic;
}

.normal {
    font-style: normal !important;
}

.MuiDataGrid-root {
    position: static !important;
    height: auto;
}

.override-height {
    height: 40px !important;
}

span {
    font-style: normal;
}

.searchTitle {
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    width: 100%;
    margin: 2% 0 1% 0;
}

.mainHR {
    border: solid 0.5px #cccccc;
    width: 100%;
}

.Quote {
    padding: 6% 6%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 300;
}

/*Tabs*/
.ant-tabs {
    width: 100%;
}

/*Chips*/
.chip-price {
    border-color: rgb(69, 126, 81) !important;
}

.chip-price > * {
    color: rgb(69, 126, 81) !important;
}

.chip-stock {
    border: solid 1px rgb(106, 66, 143) !important;
}

.chip-stock > * {
    color: rgb(106, 66, 143) !important;
}

.chip-cert {
    border-color: rgb(0, 33, 139) !important;
}

.chip-cert > * {
    color: rgb(0, 33, 139) !important;
}

.chip-condition {
    border-color: rgb(100, 124, 204) !important;
}

.chip-condition > * {
    color: rgb(100, 124, 204) !important;
}


/* Title */
.Quote__title {
    font-size: 50px;
    text-align: center;
    width: 100%;
}

/* Search */
.Quote__search {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.Quote__search > * {
    margin: 0 0 2% 0 !important;
}

/* Results */
.Quote__results {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1%;
}

.Quote__results__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
}

.Quote__results__container > Button:first-child {
    margin-bottom: 2%;
}

.Quote__results__container__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(218, 237, 255);
    padding: 2%;
}

.Quote__results__container__header > div:last-child {
    display: flex;
    align-items: center;
}

.Quote__results__container__header > div:last-child  > * {
    margin: 0 2%;
}

.Quote__results__container > div:last-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    padding: 3%;
    font-size: 15px;
    text-align: left;
}

.Quote__results__container > div:last-child > div {
    margin: 3%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.Quote__results__container__stocks {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.Quote__results__container__stocks__container {
    margin: 0 10%;
}

.Quote__results__container__stocks__container p {
    min-height: 60px;
}

.Quote__results__container__stocks p {
    padding-left: 10%;
}

@media (max-width: 1200px) {
    .Quote__title {
        margin-top: 2%;
    }
}

@media (max-width: 1000px) {
    .Quote__title {
        margin-top: 5%;
    }
}

@media (max-width: 770px) {
    .rc-table {
        width: auto;
        overflow-x: scroll;
    }

    .rc-table-row {
        border-bottom: solid 1px #cccccc;
    }
    
    .rc-table-cell {
        border-right: solid 1px #cccccc;
    }

    .rc-table-cell {
        padding: 1%;
    }

    .rc-table-cell > * {
        width: 110px;
    }

    .rc-table-row > td:first-child > * {
        padding-right: 15px;
    }

    .rc-table-row > td:nth-of-type(2) > *{
        padding-right: 15px;
    }

    .rc-table-row > td:nth-of-type(9) > *{
        padding-right: 25px;
    }

    .rc-table-row > td:last-child > *{
        padding-right: 25px;
    }

    .rc-table-row > td:first-child > * {
        width: 40px !important;
    }

    .Quote__search > * {
        margin: 2% 0 2% 0 !important;
    } 

    .Quote__title {
        font-size: 45px;
    }

    .Quote__results__container__header {
        flex-direction: column;
        align-items: flex-start;
        padding: 4% 1%;
    }

    .Quote__results__container__header > * {
        margin: 1% 6%;
    }

    .Quote__results__container__header > div:first-child {
        align-items: flex-start;
    }

    .Quote__results__container__header > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .Quote__results__container__header > div:last-child > * {
        margin: 5% 0;
    }    
}

@media (max-width: 600px) {
    .bold {
        font-size: 23px;
    }

    .bold_2 {
        font-size: 20px;
    }

    .Quote__title {
        margin-top: 8%;
        font-size: 42px;
    }

    .Quote__search > * {
        margin: 4% 0 2% 0 !important;
    } 

    .Quote__results__container > div:last-child {
        font-size: 14px;
    }

    .Quote__results__container__header {
        padding: 7% 2%;
    }

    .Quote__results__container__header > div:last-child > * {
        margin: 5% 0;
    }    
}

@media (max-width: 450px) {
    .bold {
        font-size: 21px;
    }

    .bold_2 {
        font-size: 17px;
    }

    .Quote__title {
        margin-top: 15%;
        font-size: 40px;
    }

    .Quote__search > * {
        margin: 5% 0 2% 0 !important;
    }

    .Quote__results__container > div:last-child {
        font-size: 13px;
    }

    .Quote__results__container > div:last-child > div {
        margin: 10% 0;
    }

    .Quote__results__container__header {
        padding: 8% 3%;
    }

    .Quote__results__container__header > div:last-child > * {
        margin: 6% 0;
    }    
}

@media (max-width: 300px) {
    .bold {
        font-size: 20px;
    }

    .bold_2 {
        font-size: 17px;
    }

    .Quote__title {
        margin-top: 24%;
        font-size: 38px;
    }

    .Quote__search > * {
        margin: 8% 0 2% 0 !important;
    }

    .Quote__results__container > div:last-child {
        font-size: 12px;
    }

    .Quote__results__container__header > div:last-child > * {
        margin: 8% 0;
    }    
}