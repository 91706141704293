/* General */
.refresh:hover {
    cursor: pointer;
}

/* Component */
.TableComponent__displayFlex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.TableComponent__displayFlex p {
    margin-bottom: 0;
}

.TableComponent__displayFlex span {
    margin-left: 10px;
}

.TableComponent__dropdown {
    margin-top: 2%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; 
}

.TableComponent__dropdown p {
    margin: 0 10px 0 0;
}

.TableComponent__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.TableComponent__pagination > * {
    margin: 0 5px;
}

.TableComponent__pagination p {
    margin-bottom: 0;
}


/* Table */
.rc-table {
    margin: 3% 0;
}

.rc-table-cell {
    padding: 1% 2%;
    text-align: left;
}

.rc-table-cell > * {
    text-align: left;
    margin-bottom: 0 !important;
}

.rc-table-thead > tr > * {
    font-weight: 400 !important;
}

.rc-table-thead > tr > *:hover {
    cursor: pointer;
}

.rc-table-thead > tr > th:first-child {
    border-right: solid 1px #cccccc;
    text-align: center;
}

.rc-table-thead > tr > th:nth-of-type(1) {
    border-right: solid 1px #cccccc;
    text-align: center;
    background-color: black;
    color: white;
}

.rc-table-thead > tr > th:nth-of-type(9) {
    text-align: right;
}

.rc-table-thead > tr > th:last-child {
    text-align: right;
}

.rc-table-thead .rc-table-cell {
    font-weight: 300;
}

.rc-table-row {
    border-top: solid 1px #cccccc;
}

.rc-table-row:last-child {
    border-bottom: solid 1px #cccccc;
}

.rc-table-row:nth-of-type(odd) {
    background: rgb(218, 237, 255);
}

.rc-table-row:nth-of-type(even) {
    background: #F0F8FF;
}

.rc-table-row > td:first-child > * {
    /* color: black !important; */
    text-align: center;
}

.rc-table-row > td:nth-of-type(1) {
    background: white;
    font-weight: 400;
    border-right: solid 1px #cccccc;
}

.rc-table-row > td:nth-of-type(8) > * {
    text-align: right;
}

.rc-table-row > td:nth-of-type(1) {
    background: black;
    color: white;
    text-align: center;
    border-right: solid 1px #cccccc
}

.rc-table-row > td:nth-of-type(1) a {
    color: white;
    transition: 0.3s;
}

.rc-table-row > td:nth-of-type(1) a:hover {
    opacity: 0.7;
    transition: 0.3s;
}

.rc-table-row > td:nth-of-type(3){
    font-weight: 400;
}

.rc-table-row > td:nth-of-type(9) > p {
    font-weight: 400;
    text-align: right;
}

.rc-table-row > td:last-child  > p {
    font-weight: 400;
    text-align: right;
}
