/*General*/
.noHover {
    font-weight: 500 !important;
}

.noHover:hover {
    opacity: 1 !important;
}


/*Footer Div*/
.Footer {
    padding: 6% 2%;
    background: #000000;
    font-weight: 400;
    color: white;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.Footer div {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.Footer > p:nth-child(3) {
    font-size: 10px;
}

.Footer a {
    color: white;
    text-decoration: none;
    transition: 0.2s;
}

.Footer p {
    color: white;
    transition: 0.3s;
}

.Footer p:hover {
    color: white;
    transition: 0.3s;
    opacity: 0.7;
}

@media (max-width: 1200px) {
    .Footer {
        padding: 7% 3%;
    }

    .Footer * {
        margin-top: 1%;
    }

    .Footer > p:first-child {
        margin-top: none !important;
    }
}

@media (max-width: 1000px) {
    .Footer {
        padding: 8% 3%;
    }

    .Footer p {
        font-size: 12px;
    }
}

@media (max-width: 770px) {
    .Footer {
        padding: 8% 5%;
    }

    .Footer p {
        font-size: 11px;
    }

    .Footer div {
        font-size: 11px;
        flex-wrap: wrap;
    }

    .Footer div p {
        flex-basis: 50%;
    }
}

@media (max-width: 600px) {
    .Footer {
        padding: 12% 5%;
    }
}

@media (max-width: 500px) {
    .Footer {
        padding: 13% 8%;
    }

    .Footer * {
        margin-top: 2%;
    }

    .Footer div {
        flex-wrap: nowrap;
        flex-direction: column;
    }
}

@media (max-width: 400px) {
    .Footer {
        padding: 14% 8%;
    }
}

@media (max-width: 350px) {
    .Footer {
        padding: 25% 8%;
    }

    .Footer * {
        margin-top: 3%;
    }
}