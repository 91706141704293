.ant-btn {
    color: white;
    background-color: rgb(44, 160, 214);
    border-color: rgb(44, 160, 214);
    font-size: 13px !important;
    letter-spacing: 0.2rem;
    width: 200px;
    height: 40px !important;
}

.ant-btn:hover {
    color: white;
    border-color: rgb(44, 160, 214);
    background-color: rgb(44, 160, 214, 0.6);
}

video {
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.Homepage {
    background-color: #000000;
    color: white;
    height: 100vh;
    font-weight: 300;
    font-size: 21px;
    display: flex;
    overflow-y: hidden;
}

.Homepage__Backdrop {
    height: 100%;
    width: 100%;
    z-index: 100 !important;
}

.Homepage__Loader {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}


.Homepage__content {
    z-index: 1;
    height: 100%;
    width: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Homepage__content > div {
    padding: 4%;
    text-align: center;
    color: white;
}

.Homepage__content > p {
    letter-spacing: 2rem;
    text-align: center;
    font-size: 70px;
}

.Homepage__buttons__content > * {
    margin: 0 20px;
}

 @media (max-height: 550px) {
    .Homepage {
        min-height: 550px;
    }
 }

@media (max-width: 1400px) {
    .Homepage__content > p {
        font-size: 60px;
    }
}

@media (max-width: 1000px) {
    .Homepage__content > p {
        line-height: 80px;
        font-size: 50px;
    }
}

@media (max-width: 770px) {
    .Homepage__content > p {
        font-size: 40px;
    }
}

@media (max-width: 700px) {
    .Homepage {
        font-size: 18px;
    }

    .Homepage__content > p {
        letter-spacing: 20px;
    }
}

@media (max-width: 600px) {
    .ant-btn {
        font-size: 10px !important;
        height: 35px !important;
        width: 150px;
    }

    .Homepage {
        font-size: 16px;
    }

    .Homepage__content > p {
        font-size: 30px;
        font-weight: 400;
    }
}

@media (max-width: 500px) {
    .Homepage__content > p {
        font-size: 25px;
        letter-spacing: 17px;
    }
}

@media (max-width: 400px) {
    .ant-btn {
        font-size: 9px !important;
        height: 32px !important;
        width: 140px;
    }

    .Homepage {
        font-size: 14px;
    }

    .Homepage__content > p {
        font-size: 23px;
        letter-spacing: 10px;
    }
}
