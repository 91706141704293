/*Animations*/
@keyframes zoom {
    0% {
        transform: scale(1.1, 1.1);
    }
    40% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes title {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/*General*/
.About a {
    color: white !important;
}

.about-scroll-up {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);  
}

.line {
    margin: 2% auto 4% auto;
    width: 80%;
    border-bottom: 1px solid #ffffff;
}

.line-alt {
    margin: 2% auto;
    width: 70%;
    border-bottom: 1px solid #002ab3;
}

.line-alt-alt {
    margin: 2% auto;
    width: 70%;
    border-bottom: 1px solid rgb(157, 222, 248);
}

.fixed-background {
    background: -webkit-linear-gradient(120deg, #f0f0f0 50%, #e7e7e7 50%) right;
    background: linear-gradient(120deg, #ffffff 50%, #f3f6fd 50%) right;
    background-attachment: fixed;
}

.ant-btn {
    margin-top: 30px;
}

.About {
    font-size: 13px;
    font-weight: 300;  
    overflow-x: hidden;
}


/*First background*/
.About__background-first {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    animation: zoom 1.2s ease-in-out;
    overflow-x: hidden !important;
    padding: 8%;
}

.About__background-first > p:first-child {
    font-size: 85px;
    animation: title 1.5s;
}

.About__background-first > div {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 20px;
    text-align: left;
}

.About__background-first > div > a {
    transition: 0.3s;
    margin-right: 13%;
}

.About__background-first > div a:first-child {
    animation: title 2s;
}

.About__background-first > div a:nth-of-type(2) {
    animation: title 2.4s;
}

.About__background-first > div a:last-child {
    animation: title 2.8s;
}

.About__background-first > div > a:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s;
}


/*Header*/
.About__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4%;
}

.About__header > img {
    width: 300px;
    height: 80px;
}

.About__header > p {
    margin-top: 2%;
    margin-left: 4%;
    letter-spacing: 2px;
    font-size: 20px;
    text-align: center;
}

.About__header > p > span {
    color: #013A6B;
    font-weight: 400;
}


/*AAUSA & AAM*/
.About__background-second {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transition: 0.7s;
}

.About__background-second__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.About__background-second__wrapper > div:last-child {
    flex-direction: row-reverse;
}

.About__background-second__wrapper__section {
    height: 450px;
    width: 100%;
    display: flex;
}

.About__background-second__wrapper__section > div:first-child {
    background: "#000000";
    background-Image: url("https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_sixth.jpg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    flex-basis: 40%;
    margin-left: 7%;
}

.About__background-second__wrapper > div:last-child > div:first-child {
    background-Image: url("https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_seventh.jpg") !important;
    background-position: center !important;
    flex-basis: 40%;
    margin-right: 7%;
    margin-left: 0
}

.about-figure {
    flex-basis: 60% !important;
    background: #232526 !important; 
    background: -webkit-linear-gradient(to right, #414345, #232526) !important;
    background: linear-gradient(to right, #414345, #232526) !important;
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    padding: 2%;
}

.about-figure:hover {
    background: transparent !important;
}

.about-figcaption {
    padding: 10% !important;
    background: #0F2027 !important;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027) !important;
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-figcaption > p:first-child {
    font-size: 18px !important;
    text-decoration: none;
    letter-spacing: normal;
}


/*C-Statement*/
.About__cStatement {
    width: 100%;
    height: 180px;
    background: -webkit-linear-gradient(to left, #ECE9E6 50%, #fcf8f5 50%) right;
    background: linear-gradient(to left, transparent 50%, #0F2027 50%) right;
    background-size: 200%;
    transition: 2.1s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-align: center;
    margin: 6% 0;
    padding: 4%;
}

.About__background-second__wrapper:hover > .About__cStatement {
    opacity: 1;
    color: white;
    background-position: left;
}


/*Statements*/
.About__statements {
    margin-top: 4%;
    font-size: 18px;
    line-height: 45px;
    padding: 5% 8%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-evenly;
    text-align: left;
}

.About__statements > div:first-child {
    padding-right: 65%;
    margin-bottom: 5%;
}

.About__statements > div:last-child {
    padding-left: 65%;
}

.About__statements__section p:first-child {
    font-size: 40px;
    color: #002ab3;
}


/*Commitment*/
.About__commitment {
    position: relative;
    height: 100vh;
    display: flex;
    text-align: left;
    transition: 0.8s;
}

.About__commitment__title-logo {
    padding: 4%;
    height: 100%;
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.About__commitment__title-logo p {
    padding-left: 3%;
    font-size: 75px;
    line-height: 150px;
    word-spacing: 999px;
}

.About__commitment__text {
    opacity: 0;
    background: #232526 !important; 
    background: -webkit-linear-gradient(to right, #414345, #232526) !important;
    background: linear-gradient(to right, #414345, #232526) !important;
    color: white;
    transition: 0.7s ease-out;
    flex-basis: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1% 8%;
    line-height: 40px;
    font-size: 16px;
    text-align: left;
}

.About__commitment:hover .About__commitment__text {
    opacity: 1;
    transition: 1.2s;
}

.About__commitment__text-mobile {
    background: #232526 !important; 
    background: -webkit-linear-gradient(to right, #414345, #232526) !important;
    background: linear-gradient(to right, #414345, #232526) !important;
    color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
    line-height: 30px;
    font-size: 13px;
    text-align: left;
}

/* @media (max-height: 870px) {
    .About__background-second {
        height: 870px;
    }

    .About__commitment {
        height: 870px;
    }
}

@media (max-height: 750px) {
    .About__background-first {
        height: 750px;
    }
    
    .About__statements {
        height: auto;
    }
} */

@media (max-width: 1200px) {
    .About__commitment__text {
        line-height: 35px;
        font-size: 15px;
    }

    .About__background-first {
        padding: 12%;
    }

    .About__background-first > p:first-child {
        font-size: 75px;
    }

    .About__header {
        padding: 4% 6%;
    }
    
    .About__header > img {
        width: 250px;
        height: 70px;
    }
    
    .About__header > p {
        margin-top: 3%;
        margin-left: 0%;
        font-size: 19px;
    }

    .about-figcaption > p:first-child {
        font-size: 16px !important;
        line-height: 40px;
    }

    .about-figure {
        font-size: 22px;
    }

    .About__statements__section {
        font-size: 18px;
        line-height: 40px;
    }

    .About__statements > div:first-child {
        padding-right: 55%;
    }
    
    .About__statements > div:last-child {
        padding-left: 55%;
    }

    .About__statements__section p:first-child {
        font-size: 35px;
        color: #002ab3;
    }

    .About__commitment__title-logo p {
        padding-left: 3%;
        font-size: 70px;
        line-height: 120px;
        word-spacing: 999px;
    }    
}

@media (max-width: 1000px) {
    .About__background-first {
        padding: 20% 0 0 13%;
    }

    .About__background-first > p:first-child {
        font-size: 62px;
    }

    .About__background-first > div {
        font-size: 18px;
    }   
    
    .About__header {
        padding: 8%;
    }
    
    .About__header > img {
        width: 200px;
        height: 60px;
    }
    
    .About__header > p {
        margin-top: 4%;
        font-size: 18px;
    }
    
    .about-figcaption > p:first-child {
        font-size: 14px !important;
        line-height: 30px;
    }

    .about-figure {
        font-size: 19px;
        padding: 4%;
    }

    .About__cStatement {
        font-size: 17px;
    }

    .About__background-second > p:first-child {
        font-size: 47px;
    }

    .About__statements__section {
        margin: 2% 0;
        font-size: 16px;
        line-height: 35px;
    }

    .About__statements > div:first-child {
        padding-right: 50%;
    }
    
    .About__statements > div:last-child {
        padding-left: 50%;
    }

    .About__statements__section p:first-child {
        font-size: 30px;
        color: #002ab3;
    }

    .About__commitment__title-logo p {
        font-size: 63px;
        line-height: 110px;
    }

    .About__commitment__text {
        padding: 4%;
        line-height: 35px;
        font-size: 13px;
    }
}

@media (max-width: 770px) {
    .about-scroll-up {
        display: none;
    }

    .cert {
        transform: scale(0.7);
    }

    .commitment-title {
        word-spacing: 99999px;
        width: 100%;
        height: auto;
        color: white;
        background: black;
        font-size: 40px;
        font-weight: 400;
        line-height: 80px;
        word-spacing: normal;
        text-align: center;
        padding: 1%;
    }

    .About__background-first {
        padding: 15%;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }

    .About__background-first > p:first-child {
        font-size: 55px;
        word-spacing: 99999px;
    }

    .About__background-first > div {
        font-size: 16px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    } 
    
    .About__background-first > div > a {
        margin: 4% 0 4% 0;
    }

    .About__header {
        padding: 10%;
    }
    
    .About__header > img {
        width: 180px;
        height: 50px;
    }
    
    .About__header > p {
        margin-top: 6%;
        font-size: 16px;
    }

    .About__background-second {
        min-height: 100vh;
        flex-direction: column;
    }

    .About__background-second__wrapper__section {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background: #fcf8f5; */
    }

    .About__background-second__wrapper__section > div:first-child {
        width: 100%;
        min-height: 400px;
        flex-basis: 100%;
        margin: 0 0 10% 0;
    }

    .About__background-second__wrapper > div:nth-of-type(2) > div:first-child {
        background-Image: url("https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_seventh.jpg") !important;
        background-position: center !important;
        flex-basis: 100%;
        margin: 0 0 10% 0;
    }

    .About__background-second__wrapper__section > p:nth-of-type(1) {
        font-size: 15px;
        font-weight: 400;
    }

    .About__background-second__wrapper__section > p:nth-of-type(2) {
        padding: 0% 10%;
        line-height: 25px;
        text-align: left;
        margin-bottom: 15%;
        font-size: 12px;
    }

    .About__cStatement {  
        background-size: 100%;
        background: #232526 !important; 
        background: -webkit-linear-gradient(to right, #414345, #232526) !important;
        background: linear-gradient(to right, #414345, #232526) !important;
        margin: 0;
        padding: 10%;
        height: auto;
        font-size: 16px;
        line-height: 30px;
        color: white;
    }

    .About__statements {
        margin-top: 0;
    }

    .About__statements__section {
        margin: 5% 0;
        font-size: 14px;
        line-height: 25px;
    }

    .About__statements > div:first-child {
        padding-right: 42%;
        margin-bottom: 5%;
    }
    
    .About__statements > div:last-child {
        padding-left: 42%;
    }

    .About__statements__section p:first-child {
        font-size: 22px;
        margin-bottom: 4%;
    }

    .About__commitment {
        padding: 3%;
        height: auto;
        flex-direction: column;
    }

    .About__commitment__title-logo {
        align-items: center;
        padding: 3%;
        flex-basis: 100%;
    }

    .About__commitment__title-logo p {
        font-size: 55px;
        font-weight: 300;
        line-height: 80px;
        word-spacing: normal;
        text-align: center;
        padding: 1%;
    }

    .About__commitment__text {
        display: none;
    }
}

@media (max-width: 590px) {
    .cert {
        transform: scale(0.6);
    }

    .about-figure {
        font-size: 16px !important;
    }

    .about-figcaption {
        padding: 7% !important;
        height: auto !important;
    }

    .about-figcaption p {
        font-size: 13px !important;
    }

    .about-figcaption-mobile {
        font-size: 12px;
        padding: 6% 12%;
        align-items: flex-start;
    }

    .About__background-first {
        padding: 20%;
    }

    .About__background-first > p:first-child {
        font-size: 50px;
    }

    .About__background-first > div {
        font-size: 15px;
    } 

    .About__background-first > div > a {
        margin: 6% 0;
    } 

    .About__header {
        padding: 10%;
    }
    
    .About__header > img {
        width: 165px;
        height: 44px;
    }
    
    .About__header > p {
        margin-top: 8%;
        font-size: 14px;
    }

    .About__background-second__wrapper__section > p:nth-of-type(1) {
        font-size: 14px;
    }

    .About__background-second__wrapper__section > p:nth-of-type(2) {
        padding: 0 15%;
    }

    .About__cStatement {
        font-size: 14px;
    }

    .About__statements__section {
        margin: 12% 0;
        font-size: 12px;
        line-height: 23px;
    }

    .About__statements > div:first-child {
        padding-right: 0;
        margin-bottom: 5%;
    }
    
    .About__statements > div:last-child {
        padding-left: 0%;
    }

    .About__statements__section p:first-child {
        font-size: 18px;
        margin-bottom: 5%;
    }

    .About__commitment__title-logo p {
        font-size: 50px;
        padding: 2%;
    }
}

@media (max-width: 480px) {
    .cert {
        transform: scale(0.5);
    }

    .about-figcaption-mobile {
        padding: 7% 14%;
    }

    .About__background-first {
        padding: 25%;
    }

    .About__background-first > p:first-child {
        font-size: 47px;
    }

    .About__background-first > div {
        font-size: 14px;
    } 

    .About__background-first > div > a {
        margin: 12% 0;
    } 

    .About__background-second-mobile > p:first-child {
        word-spacing: 99999px;
        letter-spacing: 13px;
        text-align: left;
        padding-left: 20%;
        line-height: 55px;
    }

    .About__cStatement {
        font-size: 13px;
        line-height: 33px;
    }

    .About__statements__section {
        margin: 17% 0;
        font-size: 12px;
        line-height: 23px;
    }

    .About__statements > div:first-child {
        margin-bottom: 5%;
    }

    .About__statements__section p:first-child {
        font-size: 18px;
        margin-bottom: 5%;
    }

    .About__commitment__title-logo p {
        font-size: 45px;
        padding-left: 10%;
        text-align: left;
    }

    .About__commitment__text-mobile {
        padding: 14%;
        font-size: 12px;
        line-height: 25px;
    }
}

@media (max-width: 400px) {
    .About__background-first {
        padding: 20% 10% 10% 10%;
    }

    .About__background-first > p:first-child {
        font-size: 46px;
    }

    .About__background-first > div > a {
        margin: 6% 0;
    } 

    .About__header {
        padding: 15%;
    }
    
    .About__header > img {
        width: 140px;
        height: 40px;
    }
    
    .About__header > p {
        margin-top: 20%;
        font-size: 13px;
    }

    .About__background-second {
        font-size: 30px;
    }

    .About__background-second__wrapper__section > div:first-child {
        min-height: 300px;
    }

    .About__background-second__wrapper__section > p:nth-of-type(2) {
        font-size: 11px;
    }

    .About__cStatement {
        padding: 15%;
        font-size: 13px;
        line-height: 27px;
    }

    .About__statements {
        padding: 5% 12%;
    }

    .About__statements__section {
        margin: 20% 0;
    }

    .About__commitment__title-logo p {
        padding-left: 13%;
        line-height: 70px;
        font-size: 40px;
    }
}

@media (max-width: 350px) {
    .About__background-first {
        padding: 22% 10% 10% 10%;
    }

    .About__background-first > p:first-child {
        font-size: 42px;
    }

    .About__background-first > div{
        font-size: 13px;
    } 

    .About__background-first > div > a {
        margin: 7% 0;
    } 

    .About__commitment__title-logo p {
        padding-left: 13%;
        line-height: 55px;
        font-size: 35px;
    }

    .cert {
        transform: scale(0.4);
    }
}