.remove {
    display: none !important;
}

/*Animations*/
@keyframes drop {
    0% {
        top: -300px;
    }
    50% {
        top: -300px;
    }
    100% {
        top: 0px;
    }
}

/*General*/
.displayFlex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.3s;
}

.displayFlex p {
    transition: 0.3s;
}

.displayFlex > p:hover {
    transition: 0.3s ease-out;
    font-size: 30px;
}

.downArrow {
    margin-left: 5px;
}

.sub {
    font-size: 16px;
    letter-spacing: 4px;
}

.sub_sub {
   font-weight: 300;
   font-size: 12px;
}

.Navbar__animation {
    animation: drop 3s ease-out !important;
}

/*Antd*/
.ant-dropdown-menu-title-content > p {
    margin: 2% 0;
}

/*Rodal*/
.rodal-left {
    height: 100% !important;
    width: 100% !important; 
    padding: 2% !important;
}

.rodal-mobile {
    height: 100% !important;
    width: 90% !important; 
    padding: 2% !important;
}

.rodal-dialog {
    height: 100% !important;
    width: 100% !important;
    background: #000000 !important;
    background: -webkit-linear-gradient(to left, #ECE9E6 50%, #434343 50%) right !important;
    background: linear-gradient(to left, #ECE9E6 50%, #434343 50%) right !important; 
    overflow-y: auto;
    background-size: 200% !important;
    transition: .5s ease-out !important;
    color: black !important;
}

.rodal-dialog:hover {
    background-position: left !important;
    transition: 0.5s ease-out !important;
}

.Rodal {
    width: 100%;
    height: 100%;
    display: flex;
}

.Rodal-mobile {
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
}

.Rodal-mobile-first {
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
}


/*Div of Rodal*/
.Team {
    padding: 2% 5%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-weight: 300;
    font-size: 25px;
}

.Team:hover {
    color: white !important;
}

.Team > * {
    margin: 3% 0% !important;
}

.Team > p:first-child {
    font-size: 50px;
}

.Team > div:last-child {
    padding-bottom: 5% !important;
}

/*Div of Rodal - Mobile*/
.Team-mobile {
    padding: 2%;
    padding-top: 18%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-weight: 300;
    font-size: 16px;
    color: white;
}

.Team-mobile > * {
    margin: 6% 0% !important;
}

.Team-mobile > p:first-child {
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    margin-bottom: 16%;
}

.Team-mobile-first {
    padding: 2%;
    padding-top: 5%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-weight: 300;
    font-size: 13px;
    color: white;
}

.Team-mobile-first > * {
    margin: 1% 0% !important;
}

.Team-mobile-first > p:first-child {
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    margin-bottom: 4% !important;
}


/*Hamburger*/
.bm-burger-button {
    position: fixed;
    width: 20px;
    height: 17px;
    right: 2vh;
    top: 13px;
}

.bm-burger-bars {
    background: #bdc3c7;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-menu {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
    padding: 15%;
    font-size: 14px;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-item-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.bm-item-list a {
    color: black;
}


/*Navbar*/
.links {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 150px;
    height: 40%; 
}

.links a {
    color: black !important;
}

.Navbar {
    padding: 1px;
    background: #8e9eab;
    background: -webkit-linear-gradient(to right, rgb(238, 242, 243, 1), rgb(145, 162, 175, 0));
    background: linear-gradient(to right, rgb(238, 242, 243, 1), rgb(145, 162, 175, 0));
    color: black;
    position: absolute;
    z-index: 10;
    height: 60px;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.Navbar > div {
    display: flex;
    align-items: center;
}

.Navbar__links {
    width: auto;
}

.Navbar__links > * {
    color: white;
    text-decoration: none;
    margin-right: 50px;
}

.Navbar__links > *:last-child {
    margin-right: none;
}

.Navbar__links > *:after {
    display: block;
    content: '';
    border-bottom: solid 1.7px rgb(255, 255, 255);
    transform: scaleX(0);
    transition: transform 350ms ease-in-out;
    transform-origin: 100% 50%;
}

.Navbar__links > *:hover {
    color: white;
}

.Navbar > div > *:hover:after {
    transform: scaleX(1); 
    transform-origin: 0% 50%;
}

.Navbar__links-contrast > * {
    color: black !important;
    text-decoration: none;
    margin-right: 50px;
}

.Navbar__links-contrast > *:last-child {
    margin-right: none;
}

.Navbar__links-contrast > *:hover {
    color: black;
}

.Navbar__links-contrast > *:after {
    display: block;
    content: '';
    border-bottom: solid 1.7px rgb(0, 0, 0);
    transform: scaleX(0);
    transition: transform 350ms ease-in-out;
    transform-origin: 100% 50%;
}

/* Navbar-Mobile */
.Navbar-Mobile__Links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Navbar-Mobile__Links > div:first-child {
    margin: 25% 0;
}

.Navbar-Mobile__Links > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90px;
}

.Navbar-Mobile__Links > div:last-child {
    margin-top: 20%;
}


@media (max-width: 1200px) {
    .sub {
        font-size: 15px;
    }

    .Team > p:first-child {
        font-size: 40px;
    }

    .Team {
        font-size: 22px;
    }
}

@media (max-width: 1000px) {
    .sub {
        font-size: 14px;
    }

    .Team > p:first-child {
        font-size: 35px;
    }

    .Team {
        font-size: 20px;
        padding: 5% 8%;
    }
}

@media (max-width: 770px) {
    .rodal-close {
        left: 4% !important;
    }

    .rodal-dialog {
        height: 100% !important;
        width: 100% !important;
        background: #434343 !important;
        color: white !important;
    }
}

@media (max-width: 600px) {
    .sub {
        font-size: 13px;
        letter-spacing: 2px;
    }

    .Team > p:first-child {
        font-size: 28px;
        margin-bottom: 15% !important;
    }

    .Team {
        font-size: 18px;
        padding: 7% 8%;
    }
}

@media (max-width: 400px) {
    .sub {
        font-size: 13px;
    }

    .Team > p:first-child {
        font-size: 25px;
        margin-bottom: 22% !important;
    }

    .Team {
        font-size: 16px;
        padding: 12% 8%;
    }
}