.RegistrationTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2% 0;
}

.RegistrationTab__Backdrop {
    height: 100%;
    width: 100%;
    z-index: 100 !important;
}

.RegistrationTab__Loader {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.RegistrationTab__Modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.RegistrationTab__Modal > * {
    margin: 2% 0;
}

.RegistrationTab__Modal:hover {
    background: white;
}

.RegistrationTab__Title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 1%;
    text-align: left;
}

.RegistrationTab__Title > p:first-child{
    font-size: 30px;
    margin-bottom: 1%;
}

.RegistrationTab__Errors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-style: italic;
    margin: 1% 0;
    padding: 1%;
}

.RegistrationTab__Errors > p:first-child {
    font-weight: 400;
    font-style: normal;
}

.RegistrationTab__Inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.RegistrationTab__Inputs > * {
    width: 25%;
    margin: 1% 0 !important;
}

.RegistrationTab__Inputs > *:last-child {
    width: 25%;
}

@media (max-width: 1000px) {
    .RegistrationTab__Title {
        margin: 2% 0 4% 0;
    }

    .RegistrationTab__Inputs > * {
        width: 50%;
        margin: 2% 0 !important;
    }    
    

    .RegistrationTab__Title > p:first-child {
        font-size: 26px;
    }
}

@media (max-width: 800px) {
    .RegistrationTab__Inputs > *:last-child {
        width: 30%;
    }

    .RegistrationTab__Title > p:first-child {
        font-size: 22px;
    }
    .RegistrationTab__Errors {
        font-size: 13px;
        margin: 5% 0;
    }
}

@media (max-width: 600px) {
    .RegistrationTab {
        padding: 3% 4%;
    }

    .RegistrationTab__Errors {
        font-size: 12px;
        margin: 7% 0;
    }

    .RegistrationTab__Title {
        font-size: 12px;
    }

    .RegistrationTab__Title > p:first-child {
        font-size: 20px;
    }

    .RegistrationTab__Inputs > * {
        margin: 5% 0 !important;
        width: 100%;
    }

    .RegistrationTab__Inputs > *:last-child {
        font-size: 12px !important;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .RegistrationTab__Title > p:first-child {
        font-size: 18px;
        margin-bottom: 5%;
    }

    .RegistrationTab__Inputs > * {
        margin: 7% 0 !important;
    }
}
