.Login {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 5%;
}

.Login__Backdrop {
    height: 100%;
    width: 100%;
    z-index: 100 !important;
}

.Login__Loader {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

@media (max-height: 700px) {
    .Login {
        height: 700px;
    }
}