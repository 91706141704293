.Parser {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-height: 700px) {
    .Parser {
        height: 700;
    }
}